<form [formGroup]="form" (submit)="trySubmit()">
  <h3>{{ concern !== '' ? concern : 'Login' }}</h3>
  <mat-form-field class="fullwidth">
    <input
      matInput
      placeholder="Enter your username"
      type="text"
      autocomplete="username"
      name="username"
      formControlName="username"
      required
    />
  </mat-form-field>
  <mat-form-field class="fullwidth">
    <input
      matInput
      placeholder="Enter your password"
      type="password"
      autocomplete="current-password"
      name="password"
      formControlName="password"
      required
    />
  </mat-form-field>
  <div class="button-row">
    <button
      id="btn-cancel"
      mat-stroked-button
      type="button"
      (click)="dialogRef.close(false)"
      [disabled]="waitingForResponse"
    >
      Cancel
    </button>
    <button
      id="btn-login"
      mat-stroked-button
      type="submit"
      color="primary"
      [disabled]="!form.valid || waitingForResponse"
    >
      Login
    </button>
  </div>
  <p>
    Don't have an account?
    <a href="javascript:void(0)" (click)="openRegistrationDialog()">Register</a>
  </p>
  <div class="divider">
    <span class="divider-line"></span>
    <span class="divider-text">or</span>
    <span class="divider-line"></span>
  </div>
  <button
    mat-stroked-button
    class="sso-button"
    type="button"
    (click)="loginWithSAML()"
    [disabled]="waitingForResponse"
  >
    <img src="assets/icons/nfdi4c.svg" alt="SAML" class="sso-icon" />
    <span>Login with NFDI4C</span>
  </button>
</form>
<div class="errors" *ngIf="loginFailed">Failed to login.<br />Check username and password.</div>

<hr />

<div class="help">
  <h3>Need help?</h3>
  <div class="help-row">
    <a href="javascript:void(0)" (click)="openForgotUsernameDialog()">Forgot username?</a>
    <a href="javascript:void(0)" (click)="openForgotPasswordDialog()">Forgot password?</a>
  </div>
</div>
